const ClientStatusEnum = {
  NotVerified: -1,
  DocumentsNotUpload: 0,
  Pending: 1,
  Approved: 2,
  Refused: 3,
};

const UserRoleEnum = {
  Administrator: 1,
  User: 2,
};

const UserStatusEnum = {
  Active: 1,
  Passive: 2,
};

const AppSettingType = {
  ReadOnly: 0,
  Textbox: 1,
  Checkbox: 2,
  Button: 3,
  TextboxNumber: 4,
  TextboxCharacter: 5,
  File: 6,
  RadioButton: 7,
  Dropdown: 8,
  Hidden: 9,
};

const ClientDocumentType = {
  RegistrationDocument: 1,
  IdentificationDocument: 2,
  ConfirmationDocument: 3,
  VATRegistrationDocument: 4,
  IdentificationDocumentOfAuthorizedPerson: 5,
  AttorneyToActOnBehalfDocument: 6,
  PaymentProofProfature: 7,
};

const TypeOfActivity = {
  IsCarTrader: 1,
  IsNotCarTrader: 2,
};

const CarAuctionEnum = {
  UnderConsideration: 1,
  ConfirmedCars: 2,
  CancelledCars: 3,
  ReceivedCars: 4,
};

const AuctionStatusEnum = {
  Opened: 1,
  inPreview: 2,
};

const StatusEnum = {
  Active: 1,
  Passive: 2,
};

const DefService = {
  Invoice: 1,
  Proforma: 2,
};

export {
  DefService,
  ClientStatusEnum,
  UserRoleEnum,
  UserStatusEnum,
  ClientDocumentType,
  TypeOfActivity,
  AppSettingType,
  CarAuctionEnum,
  AuctionStatusEnum,
  StatusEnum,
};
