import React, { useEffect, useState } from "react";
import { Button, Modal, Radio } from "antd";
import { CiSquareInfo } from "react-icons/ci";
import { DefService, StatusEnum } from "../../enum";
import Input from "../../utilities/Input/Input";
import {
  CreateDefServiceInvoice,
  EditDefServiceInvoice,
  GetServiceInvoiceById,
} from "../../endpoint";
import toast from "react-hot-toast";
import Preloader from "../../utilities/Preloader/Preloader";

const initialState = {
  description: "",
  priceWithoutVAT: 0,
  vatRate: 0,
  priceWithVAT: 0,
  invoiceType: DefService.Proforma,
  statusId: StatusEnum.Active,
};

const CreateServiceInvoiceModal = ({
  showModal,
  onCancel,
  setServices,
  selectedService,
}) => {
  const [inputErr, setInputErr] = useState("");
  const [service, setService] = useState(initialState);
  const [isReady, setIsReady] = useState(false);



  useEffect(() => {
    if (showModal) {
      if (selectedService) {
        GetServiceInvoiceById(selectedService).then((res) => {
          setService(res.data);
          setIsReady(true);
        });
      } else {
        setService({
          description: "",
          priceWithoutVAT: 0,
          vatRate: 0,
          priceWithVAT: 0,
          invoiceType: DefService.Proforma,
          statusId: StatusEnum.Active,
        });
        setIsReady(true);
      }
    }
  }, [showModal]);

  const handleSave = () => {
    if (selectedService == null) {
      handleSaveInvoiceService();
    } else {
      handleEditInvoiceService();
    }
  };

  const handleSaveInvoiceService = () => {
    if (service.description == "") {
      toast.error("Description can not be empty!", {
        duration: 2000,
        id: "textareaEmpty",
      });
      setInputErr("error-input");
    } else {
      CreateDefServiceInvoice(service).then((res) => {
        if (res.data.success) {
          toast.success(res.data.message, {
            duration: 2000,
            id: "SaveInvoiceService",
          });
          setInputErr("");
          setServices((prevServices) => [
            {
              description: service.description,
              priceWithoutVAT: service.priceWithoutVAT,
              vatRate: service.vatRate,
              priceWithVAT: service.priceWithVAT,
              createdOn: new Date().toISOString(),
              statusId: service.statusId,
              invoiceType: service.invoiceType,
            },
            ...prevServices,
          ]);
        } else {
          toast.error("Failed to add invoice service!", {
            duration: 2000,
            id: "textareaEmpty",
          });
        }
        onCancel();
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let priceWithVat;
    if ([e.target.name] == "priceWithoutVAT") {
      priceWithVat =
        (parseInt(e.target.value) || 0) + parseInt(service.vatRate || 0);
    } else if ([e.target.name] == "vatRate") {
      priceWithVat =
        parseInt(service.priceWithoutVAT || 0) +
        (parseInt(e.target.value) || 0);
    } else {
      priceWithVat =
        (parseInt(service.priceWithoutVAT) || 0) +
        (parseInt(service.vatRate) || 0);
    }

    setService((prev) => ({
      ...prev,
      [name]: value,
      priceWithVAT: priceWithVat,
    }));
  };

  const handleEditInvoiceService = () => {
    if (service.description == "") {
      toast.error("Description can not be empty!", {
        duration: 2000,
        id: "textareaEmpty",
      });
      setInputErr("error-input");
    } else {
      EditDefServiceInvoice(service, selectedService).then((res) => {
        if (res.data.success) {
          toast.success(res.data.message, {
            duration: 2000,
            id: "SaveInvoiceService",
          });
          setInputErr("");
          setServices((prevServices) => {
            const updatedServices = prevServices.map((prevService) =>
              prevService.id === selectedService
                ? {
                    ...prevService,
                    description: service.description,
                    priceWithoutVAT: service.priceWithoutVAT,
                    vatRate: service.vatRate,
                    priceWithVAT: service.priceWithVAT,
                    createdOn: new Date().toISOString(),
                    statusId: service.statusId,
                    invoiceType: service.invoiceType,
                    
                  }
                : prevService
            );
            return updatedServices;
          });
        } else {
          toast.error("Failed to edit invoice service!", {
            duration: 2000,
            id: "textareaEmpty",
          });
        }
        onCancel();
      });
    }
  };

  return (
    <>
      <Modal
        className="invoicemodal create-serviceinvoice"
        title={
          <>{selectedService == null ? "Create" : "Edit"} service invoice</>
        }
        centered
        open={showModal}
        onOk={handleSaveInvoiceService}
        onCancel={onCancel}
        okText={"Save"}
        footer={[
          <Button key="back" onClick={onCancel}>
            Cancel
          </Button>,
          <Button key={"add"} type="primary" onClick={() => handleSave()}>
            Save
          </Button>,
        ]}
      >
        <Preloader isReady={isReady}>
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1.5rem",
              margin: "1rem auto",
            }}
            noValidate
          >
            <div>
              <Input
                labelText={<>Description</>}
                type="text"
                value={service.description}
                name="description"
                onChange={handleInputChange}
                className={
                  service.description?.trim().length === 0 ? inputErr : ""
                }
              />
            </div>
            <div>
              <Input
                labelText={<>Price Without VAT</>}
                type="number"
                value={service.priceWithoutVAT || 0}
                name="priceWithoutVAT"
                onChange={handleInputChange}
                className={
                  String(service.priceWithoutVAT).trim() === "" ? inputErr : ""
                }
              />
            </div>

            <div>
              <Input
                labelText={<>VAT Rate</>}
                type="number"
                value={service.vatRate || 0}
                name="vatRate"
                onChange={handleInputChange}
                className={
                  String(service.vatRate).trim() === "" ? inputErr : ""
                }
              />
            </div>

            <div>
              <Input
                disabled={true}
                labelText={<>Price With VAT</>}
                type="number"
                value={
                  (parseInt(service?.priceWithoutVAT) || 0) +
                  (parseInt(service?.vatRate) || 0)
                }
                name="priceWithVAT"
                //  onChange={handleInputChange}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
              }}
            >
              <label className="input-label" htmlFor="">
                Service for
              </label>
              <Radio.Group
                onChange={(e) => {
                  setService((prev) => ({
                    ...prev,
                    invoiceType: e.target.value,
                  }));
                }}
                value={service.invoiceType}
              >
                <Radio value={DefService.Proforma}>Proforma</Radio>
                <Radio value={DefService.Invoice}>Invoice</Radio>
              </Radio.Group>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
              }}
            >
              <label className="input-label" htmlFor="">
                Status
              </label>
              <Radio.Group
                onChange={(e) => {
                  setService((prev) => ({ ...prev, statusId: e.target.value }));
                }}
                value={service.statusId}
              >
                <Radio value={StatusEnum.Active}>Active</Radio>
                <Radio value={StatusEnum.Passive}>Passive</Radio>
              </Radio.Group>
            </div>
          </form>
        </Preloader>
      </Modal>
    </>
  );
};

export default CreateServiceInvoiceModal;
