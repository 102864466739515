import React from "react";
import { Table } from "antd";
import dayjs from "dayjs";

const BidTable = ({ data }) => {
  const columns = [
    {
      title: "Client",
      dataIndex: "cLientName",
      key: "cLientName",
      render: (_, record) => (
        <span>
      {record?.bussinessName} ({record?.cLientName} {record?.clientLastName})
        </span>
      ),
    },
    {
      title: "Date",
      dataIndex: "bidDate",
      key: "bidDate",
      render: (bidDate) => (
        <span>{dayjs(bidDate).format("YYYY-MM-DD hh:mm:ss")}</span>
      ),
    },
    {
      title: "Bid Amount",
      dataIndex: "price",
      key: "price",
      render: (price) => <span>{price.toLocaleString()}€</span>,
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      
      size="large"
    />
  );
};
export default BidTable;
