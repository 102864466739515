import React, { useEffect, useState } from "react";
import {
  CreateDefServiceInvoice,
  DeleteInvoiceService,
  GetInvoiceServices,
} from "../../endpoint";
import Preloader from "../../utilities/Preloader/Preloader";
import { Badge, Table } from "reactstrap";
import { Button, Popconfirm } from "antd";
import { StatusEnum } from "../../enum";
import toast from "react-hot-toast";
import "./DefInvoiceServices.css";
import CreateServiceInvoiceModal from "../Modals/CreateServiceInvoiceModal";

const DefInvoiceServicesList = () => {
  const [services, setServices] = useState();
  const [isReady, setIsReady] = useState(false);
  const [showCreateServiceModal, setShowCreateServiceModal] = useState(false);
  const [selectedService, setSelectedService] = useState();

  useEffect(() => {
    GetInvoiceServices().then((res) => {
      setServices(res.data.list);
      setIsReady(true);
    });
  }, []);

  function getServiceStatus(status) {
    if (status == StatusEnum.Active) {
      return "Active";
    }
    if (status == StatusEnum.Passive) {
      return "Passive";
    }
  }

  function getServiceClass(status) {
    if (status == StatusEnum.Active) {
      return "approved-bg";
    }
    if (status == StatusEnum.Passive) {
      return "pending-bg";
    }
  }

  const handleDeleteService = (id) => {
    DeleteInvoiceService(id).then((res) => {
      if (res.data.success) {
        toast.success(res.data.message, {
          duration: 2000,
          id: "deleted",
        });
        setServices((prev) => {
          return prev.filter((item) => item.id !== id);
        });
      } else {
        toast.error(res.data.message, {
          duration: 2000,
          id: "failedToDelete",
        });
      }
    });
  };

  return (
    <div className="profile-details">
      <CreateServiceInvoiceModal
        showModal={showCreateServiceModal}
        // handleSave={() => handleSaveInvoiceService}
        onCancel={() => {
          setShowCreateServiceModal(false);
        }}
        setServices={setServices}
        selectedService={selectedService}
      />
      <div className="profile-details__title def-invoice-services">
        <h2>Def Invoice Services</h2>
        <Button
          className="cars-button create-service-btn"
          onClick={() => {
            setShowCreateServiceModal(true);
            setSelectedService(null);
          }}
        >
          Create new invoice service
        </Button>
        <div className="profile-details__input-content search-content search-content--width"></div>

        <Preloader isReady={isReady}>
          <Table responsive className="invoices-table invoices">
            <thead>
              <tr className="table-titles">
                <th className="th__width upper-mid-with">Description</th>
                <th className="th__width mid-width">Price Without VAT</th>
                <th className="th__width mid-width">VAT Rate</th>
                <th className="th__width">Price With VAT</th>
                {/* <th className="th__width">Created By</th> */}
                <th className="th__width">Created On</th>
                <th className="th__width">Status</th>
                <th className="th__width"></th>
              </tr>
            </thead>
            <tbody>
              {services?.map((data, index) => {
                return (
                  <tr
                    key={data?.id}
                    className={`tr-fontSize ${
                      index % 2 != 0 ? "table-striped" : ""
                    }`}
                  >
                    <td>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column-reverse",
                          gap: "10px",
                        }}
                      >
                        <span className="align-middle " style={{paddingLeft: "10px"}}>
                          {data?.description}
                        </span>
                        <Badge
                          pill
                          color="success"
                          style={{ width: "fit-content" }}
                          className={`me-1 ${data?.invoiceType === 1 ? "not-verified-bg" : "pending-bg"}`}
                        >
                          {data?.invoiceType === 1 ? "Invoice" : "Proforma"}
                        </Badge>
                      </div>
                    </td>
                    <td>€{data?.priceWithoutVAT}</td>
                    <td>€{data?.vatRate}</td>
                    <td>€{data?.priceWithVAT}</td>
                    {/* <td>{data?.createdBy}</td> */}
                    <td>{data?.createdOn?.substring(0, 10)}</td>
                    <td>
                      <Badge
                        pill
                        color="success"
                        className={`me-1 ${getServiceClass(data?.statusId)}`}
                      >
                        {getServiceStatus(data?.statusId)}
                      </Badge>
                    </td>
                    <td>
                      <div className="action-content">
                        <Button
                          className="cars-button"
                          onClick={() => {
                            setSelectedService(data?.id);
                            setShowCreateServiceModal(true);
                          }}
                        >
                          Edit
                        </Button>
                        <Popconfirm
                          title={"Delete Service Invoice"}
                          description={"Are you sure you want to delete this?"}
                          okText={"Yes"}
                          cancelText={"No"}
                          onConfirm={() => {
                            handleDeleteService(data?.id);
                          }}
                        >
                          <Button type="primary" danger ghost>
                            Delete
                          </Button>
                        </Popconfirm>
                      </div>
                    </td>
                  </tr>
                );
              })}
              {services?.length === 0 && (
                <tr>
                  <td colSpan="11" style={{ textAlign: "center" }}>
                    No results
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Preloader>
      </div>
    </div>
  );
};

export default DefInvoiceServicesList;
