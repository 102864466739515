import React, { useEffect, useState } from "react";
import {
  UserOutlined,
  UnorderedListOutlined,
  UserAddOutlined,
  DollarOutlined,
  InboxOutlined,
  MailOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IoCarSportSharp } from "react-icons/io5";
import "./Menu.css";
import { GetAppServices, webControls } from "../../endpoint";
import { CarAuctionEnum } from "../../enum";
import { MdOutlineDashboard } from "react-icons/md";
import { TbFileInvoice } from "react-icons/tb";

function getItem(label, key, icon, children, type, onClick, className) {
  return {
    key,
    icon,
    children,
    label,
    type,
    onClick,
    className,
  };
}

// submenu keys of first level
const rootSubmenuKeys = [
  "sub1",
  "sub2",
  "sub3",
  "sub4",
  "sub5",
  "sub6",
  "sub7",
];

function SideBar({ isOpen, onClose, setOpen }) {
  const [appServices, setAppServices] = useState([]);
  const [activeLink, setActiveLink] = useState("");

  const location = useLocation();

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location]);

  useEffect(() => {
    GetAppServices().then((res) => {
      const appServicesArray = [];

      res.data.forEach((el) => {
        appServicesArray.push(
          getItem(
            el.name,
            el.id,
            null,
            null,
            null,
            () => {
              handleArvalServiceNavigate(el.name);
            },
            activeLink === `/dashboard/external-api/${el.name}`
              ? "ant-menu-item-selected"
              : ""
          )
        );
      });
      setAppServices(appServicesArray);
    });
  }, []);

  const navigate = useNavigate();

  const id = useParams();

  const handleDashboardNavigate = () => {
    setActiveLink("/dashboard/home");
    navigate("/dashboard/home");
    if (window.innerWidth <= 1200) {
      setOpen(setOpen);
    }
  };

  const handleUsersListNavigate = () => {
    setActiveLink("/dashboard/users-list");
    navigate("/dashboard/users-list");
    if (window.innerWidth <= 1200) {
      setOpen(setOpen);
    }
  };
  const handleAddUsersListNavigate = () => {
    setActiveLink("/dashboard/add-user");
    navigate("/dashboard/add-user");
    if (window.innerWidth <= 1200) {
      setOpen(setOpen);
    }
  };

  const hanldeClientsListsNavigate = () => {
    setActiveLink("/dashboard/clients-list");
    navigate("/dashboard/clients-list");
    if (window.innerWidth <= 1200) {
      setOpen(setOpen);
    }
  };
  const handlePriceListNavigate = () => {
    setActiveLink("/dashboard/price-list");
    navigate("/dashboard/price-list");
    if (window.innerWidth <= 1200) {
      setOpen(setOpen);
    }
  };
  const handleServicesNavigate = () => {
    setActiveLink("/dashboard/services");
    navigate("/dashboard/services");
    if (window.innerWidth <= 1200) {
      setOpen(setOpen);
    }
  };
  const handleContactNavigate = () => {
    setActiveLink("/dashboard/contact");
    navigate("/dashboard/contact");
    if (window.innerWidth <= 1200) {
      setOpen(setOpen);
    }
  };

  const handleArvalServiceNavigate = (name) => {
    const Name = name.replace(" ", "_");
    setActiveLink(`/dashboard/external-api/${Name}`);
    navigate(`/dashboard/external-api/${Name}`);
    if (window.innerWidth <= 1200) {
      setOpen(setOpen);
    }
  };

  const handleCarsListNavigate = (status) => {
    if (status == CarAuctionEnum.ReceivedCars) {
      setActiveLink("/dashboard/received-cars");
      navigate("/dashboard/received-cars");
    } else if (status == CarAuctionEnum.ConfirmedCars) {
      setActiveLink("/dashboard/confirmed-cars");
      navigate("/dashboard/confirmed-cars");
    } else if (status == CarAuctionEnum.UnderConsideration) {
      setActiveLink("/dashboard/underconsideration-cars");
      navigate("/dashboard/underconsideration-cars");
    } else {
      setActiveLink("/dashboard/bid-history");
      navigate("/dashboard/bid-history");
    }
    if (window.innerWidth <= 1200) {
      setOpen(setOpen);
    }
  };

  const items = [
    getItem(
      "Dashboard",
      "sub1",
      <MdOutlineDashboard />,
      null,
      null,
      handleDashboardNavigate,
      activeLink === "/dashboard/home" ? "ant-menu-item-selected" : "inActive"
    ),

    getItem(
      "Clients",
      "sub2",
      <UnorderedListOutlined />,
      null,
      null,
      hanldeClientsListsNavigate,
      activeLink === "/dashboard/clients-list" ||
        activeLink ===
          `/dashboard/clients-list/client-details/${id.clientDetailsId}` ||
        activeLink === `/dashboard/clients-list/client-cars/${id.clientId}`
        ? "ant-menu-item-selected"
        : "inActive"
    ),
    getItem(
      "Pricelist",
      "sub3",
      <DollarOutlined />,
      null,
      null,
      handlePriceListNavigate,
      activeLink === "/dashboard/price-list" ||
        activeLink === "/dashboard/price-list/edit"
        ? "ant-menu-item-selected"
        : "inActive"
    ),
    getItem(
      "Services",
      "sub4",
      <InboxOutlined />,
      null,
      null,
      handleServicesNavigate,
      activeLink === "/dashboard/services" ||
        activeLink === "/dashboard/services/add-services" ||
        activeLink === `/dashboard/services/edit-service/${id.serviceId}`
        ? "ant-menu-item-selected"
        : "inActive"
    ),
    getItem(
      "Contact",
      "sub5",
      <MailOutlined />,
      null,
      null,
      handleContactNavigate,
      activeLink === "/dashboard/contact" ||
        activeLink ===
          `/dashboard/contact/contact-message/${id.contactClientId}`
        ? "ant-menu-item-selected"
        : "inActive"
    ),

    getItem("Users", "sub6", <UserOutlined />, [
      getItem(
        "Users List",
        "9",
        <UnorderedListOutlined />,
        null,
        null,
        handleUsersListNavigate,
        activeLink === "/dashboard/users-list" ||
          activeLink === `/dashboard/users-list/edit-user/${id.userId}`
          ? "ant-menu-item-selected"
          : "inActive"
      ),
      getItem(
        "Add User",
        "10",
        <UserAddOutlined />,
        null,
        null,
        handleAddUsersListNavigate,
        activeLink === "/dashboard/add-user"
          ? "ant-menu-item-selected"
          : "inActive"
      ),
    ]),
    getItem("Cars List", "sub8", <IoCarSportSharp />, [
      getItem(
        "Under consideration cars",
        "11",
        <UnorderedListOutlined />,
        null,
        null,
        () => handleCarsListNavigate(CarAuctionEnum.UnderConsideration),
        activeLink === "/dashboard/underconsideration-cars"
          ? "ant-menu-item-selected"
          : "inActive"
      ),
      getItem(
        "Confirmed cars",
        "12",
        <UnorderedListOutlined />,
        null,
        null,
        () => handleCarsListNavigate(CarAuctionEnum.ConfirmedCars),
        activeLink === "/dashboard/confirmed-cars"
          ? "ant-menu-item-selected"
          : "inActive"
      ),
      getItem(
        "Received cars",
        "13",
        <UnorderedListOutlined />,
        null,
        null,
        () => handleCarsListNavigate(CarAuctionEnum.ReceivedCars),
        activeLink === "/dashboard/received-cars"
          ? "ant-menu-item-selected"
          : "inActive"
      ),
      getItem(
        "Bid history",
        "14",
        <UnorderedListOutlined />,
        null,
        null,
        () => handleCarsListNavigate(),
        activeLink === "/dashboard/bid-history"
          ? "ant-menu-item-selected"
          : "inActive"
      ),
    ]),
    getItem(
      "Proforma Invoices",
      "sub9",
      <TbFileInvoice />,
      null,
      null,
      () => {
        setActiveLink(`/dashboard/proforma-invoices`);
        navigate("/dashboard/proforma-invoices");
        if (window.innerWidth <= 1200) {
          setOpen(setOpen);
        }
      },
      activeLink === "/dashboard/proforma-invoices"
        ? "ant-menu-item-selected"
        : "inActive"
    ),
    getItem(
      "Invoices",
      "sub10",
      <TbFileInvoice />,
      null,
      null,
      () => {
        setActiveLink(`/dashboard/invoices`);
        navigate("/dashboard/invoices");
        if (window.innerWidth <= 1200) {
          setOpen(setOpen);
        }
      },
      activeLink === "/dashboard/invoices"
        ? "ant-menu-item-selected"
        : "inActive"
    ),
    getItem(
      "Def Invoice Services",
      "sub11",
      <TbFileInvoice />,
      null,
      null,
      () => {
        setActiveLink(`/dashboard/invoice-services`);
        navigate("/dashboard/invoice-services");
        if (window.innerWidth <= 1200) {
          setOpen(setOpen);
        }
      },
      activeLink === "/dashboard/invoice-services"
        ? "ant-menu-item-selected"
        : "inActive"
    ),
    getItem("App Settings", "sub7", <SettingOutlined />, appServices),
  ];

  const [openKeys, setOpenKeys] = useState(["sub1"]);
  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };
  return (
    <>
      <div className="layout__menu" style={{ display: isOpen }}>
        <h2 onClick={handleDashboardNavigate} style={{ cursor: "pointer" }}>
          {webControls.isCarmax ? "CARMAX" : "CARMAX-KS"}
        </h2>
        <Menu
          className="menu"
          mode="inline"
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          items={items}
        />
      </div>
    </>
  );
}

export default SideBar;
