import { FaCar, FaGasPump, FaRoad } from "react-icons/fa";
import "./AuctionCarList.css";
import { GiGearStickPattern } from "react-icons/gi";
import { MdSpeed } from "react-icons/md";
import { useContext, useState } from "react";
import Protector from "../../Protector/Protector";
import { AuthContext } from "../../../Context/AuthContext";
import { Button } from "antd";
import BidHistoryModal from "../BidHistoryModal";
import { GetBidHistoryForCar } from "../../../endpoint";

// const baseImgUrl = process.env.REACT_APP_IMAGE_URL;
function BidHistoryList({
  id,
  hp,
  km,
  name,
  petrol,
  transmission,
  registrationDate,
  finishingDateTime,
  registrationNumber,
  country,
  vehicleCategory,
  isFavorite,
  src,
  setCars = () => {},
  setCarsNo = () => {},
  bidAmount = 0,
  showInvoiceButton = false,
  showConfirmButton = false,
  cars = [],
  startingPrice = 0,
  clientName = "",
  registrationMonth = null,
}) {
  const carMainImage = `${src}`;

  const isLoggedIn = useContext(AuthContext);
  const [imageError, setImageError] = useState(false);
  const [isBidHistoryModalOpen, setIsBidHistoryModalOpen] = useState(false);

  const [bidData, setBidData] = useState([]);

  const handleOpenBidHistory = (id) => {
    GetBidHistoryForCar(id).then((res) => {
      if (res.status == 200) {
        setBidData(res?.data);
        setIsBidHistoryModalOpen(true);
      }
    });
  };

  const handleImageError = () => {
    setImageError(true);
  };

  return (
    <Protector
      isLoggedIn={isLoggedIn.isLoggedIn}
      error={
        <div className="my-bids-error">You don't have access to this path!</div>
      }
    >
      <BidHistoryModal
        isBidHistoryModalOpen={isBidHistoryModalOpen}
        setIsBidHistoryModalOpen={setIsBidHistoryModalOpen}
        bidData={bidData}
      />

      <div className="car-list">
        <div className="car-list__image">
          <img
            src={
              imageError || !carMainImage
                ? "https://remktg.arval.com/buyer/assets/images/motortrade/motortrade-no-photo.svg"
                : carMainImage
            }
            alt="main-car-image"
            style={{
              height: "237px",
              width: "385px",
              objectFit: "cover",
              padding: (imageError || !carMainImage) && "6%",
              borderRight: (imageError || !carMainImage) && "1px solid #eeeeee",
            }}
            onError={handleImageError}
          />
        </div>
        <div className="car-list-content">
          <div className="car-list-content__name">
            <h3>{name}</h3>
            <Button
              onClick={() => handleOpenBidHistory(id)}
              style={{ margin: "28px 20px 0 0" }}
              type="default"
            >
              Bid History
            </Button>
          </div>
          <div className="car-list-content__paragraph">
            <span className="car-list-content__line"></span>
            <div className="car-list-content-registation">
              <span>{registrationNumber}</span>
              <span>{vehicleCategory}</span>
              <span></span>
            </div>
            <span className="car-list-content__line"></span>

            <div className="paragraph-content">
              <div className="paragraph-content__image">
                <div style={{ display: "flex" }}>
                  {(() => {
                    if (country?.toLowerCase() == "france") {
                      return (
                        <span
                          className={`flag-icon flag-icon-fr flex-shrink-0 mr-2`}
                        ></span>
                      );
                    } else if (country?.toLowerCase() == "italy") {
                      return (
                        <span
                          className={`flag-icon flag-icon-it flex-shrink-0 mr-2`}
                        ></span>
                      );
                    } else if (country?.toLowerCase() == "luxembourg") {
                      return (
                        <span
                          className={`flag-icon flag-icon-lu flex-shrink-0 mr-2`}
                        ></span>
                      );
                    } else {
                      return (
                        <span
                          className={`flag-icon flag-icon-${country?.toLowerCase()} flex-shrink-0 mr-2`}
                        ></span>
                      );
                    }
                  })()}
                  {/* <span
                    class={`flag-icon flag-icon-${country?.toLowerCase()} flex-shrink-0 mr-2`}
                  ></span> */}
                  {country?.includes(">")
                    ? country?.match(/> (.*?) </)[1]
                    : country?.trim()}
                </div>
                <span>
                  Bid amount: {bidAmount?.toLocaleString()}€ | By: {clientName}
                </span>

                {(startingPrice || startingPrice !== 0) && (
                  <span className="starting-price">
                    Start Price: <span>{startingPrice?.toLocaleString()}€</span>
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="car-list-content__type">
            <div className="type">
              <FaCar />{" "}
              <span>
                {" "}
                {registrationMonth}, {registrationDate}
              </span>
            </div>
            <span className="type__line"></span>
            <div className="type">
              <FaGasPump />
              <span>{petrol}</span>
            </div>
            <span className="type__line"></span>
            <div className="type">
              <GiGearStickPattern />
              <span>{transmission}</span>
            </div>
            <span className="type__line"></span>
            <div className="type">
              <FaRoad />
              <span>{km?.toLocaleString()} km</span>
            </div>
            <span className="type__line"></span>
            <div className="type">
              <MdSpeed />
              <span>{hp?.toLocaleString()} KW</span>
            </div>
          </div>
        </div>
      </div>
    </Protector>
  );
}

export default BidHistoryList;
