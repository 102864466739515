import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

function Preloader({ children, isReady, minHeight = "30vh" }) {
  return (
    <>
      {!isReady && (
        <div
          style={{
            minHeight: minHeight,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 24,
                  color: "#1677ff"
                }}
                spin
              />
            }
          />
        </div>
      )}
      {isReady && children}
    </>
  );
}
export default Preloader;
