import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import {
  GetClientInvoiceAndCarDetailsForAdmin,
  GetActiveInvoiceServices,
  GetInvoiceServices,
} from "../../endpoint";
import Preloader from "../../utilities/Preloader/Preloader";
import InvoiceLineInputs from "./InvoiceLineInputs";
import { v4 as uuid } from "uuid";
import { PlusOutlined } from "@ant-design/icons";

const CreateInvoiceModal = ({
  showModal,
  createInvoice,
  onCancel,
  selectedCarOfInvoice,
  invoiceLines,
  setInvoiceLines,
  descriptionError = "",
  createNewInvoice = false,
  setClientId,
  setCarId,
  invoiceType = 1,
}) => {
  const [data, setData] = useState();
  const [isReady, setIsReady] = useState(false);

  // useEffect(() => {
  //   if (showModal) {
  //     GetClientInvoiceAndCarDetailsForAdmin(selectedCarOfInvoice).then(
  //       (res) => {
  //         setData(res.data);
  //         setCarId(res.data.carAuctionId);
  //         setClientId(res.data.clientId);

  //         if (!createNewInvoice) {
  //           setInvoiceLines([
  //             {
  //               id: uuid(),
  //               description: "Car Price",
  //               priceWithoutVAT: res.data.carPrice,
  //               vatRate: 0,
  //               priceWithVAT: parseInt(res.data.carPrice) + 0,
  //               quantity: 1,
  //             },
  //             {
  //               id: uuid(),
  //               description: "Transport",
  //               priceWithoutVAT: res.data.transport,
  //               vatRate: 0,
  //               priceWithVAT: parseInt(res.data.transport) + 0,
  //               quantity: 1,
  //             },
  //             {
  //               id: uuid(),
  //               description: "Bank Fee",
  //               priceWithoutVAT: res.data.bankFee,
  //               vatRate: 0,
  //               priceWithVAT: parseInt(res.data.bankFee) + 0,
  //               quantity: 1,
  //             },
  //             {
  //               id: uuid(),
  //               description: "Auction Service Fee",
  //               priceWithoutVAT: res.data.auctionServiceFee,
  //               vatRate: 0,
  //               priceWithVAT: parseInt(res.data.auctionServiceFee) + 0,
  //               quantity: 1,
  //             },
  //           ]);
  //         } else {
  //           GetActiveInvoiceServices().then((response) => {
  //             setInvoiceLines(response.data.list);
  //             const priceWithoutVat =
  //               res.data.carPrice < 15000
  //                 ? 250
  //                 : res.data.carPrice >= 15000 && res.data.carPrice < 25000
  //                 ? 450
  //                 : res.data.carPrice >= 25000 && res.data.carPrice < 35000
  //                 ? 650
  //                 : res.data.carPrice >= 35000 && res.data.carPrice < 50000
  //                 ? 850
  //                 : res.data.carPrice >= 50000
  //                 ? 1050
  //                 : 0;
  //             if (response.data.list?.length > 0) {
  //               setInvoiceLines((prev) => [
  //                 {
  //                   id: uuid(),
  //                   description: `EX-A et EUR1 ${res.data?.registrationNumber}`,
  //                   priceWithoutVAT: priceWithoutVat,
  //                   vatRate: 0,
  //                   priceWithVAT: priceWithoutVat + 0,
  //                   quantity: 1,
  //                 },
  //                 ...prev,
  //               ]);
  //             } else {
  //               setInvoiceLines([
  //                 {
  //                   id: uuid(),
  //                   description: `EX-A et EUR1 ${res.data?.registrationNumber}`,
  //                   priceWithoutVAT: priceWithoutVat,
  //                   vatRate: 0,
  //                   priceWithVAT: priceWithoutVat + 0,
  //                   quantity: 1,
  //                 },
  //               ]);
  //             }
  //           });
  //         }
  //         setIsReady(true);
  //       }
  //     );
  //   }
  // }, [showModal]);

  useEffect(() => {
    if (showModal) {
      GetClientInvoiceAndCarDetailsForAdmin(selectedCarOfInvoice).then((res) => {
        setData(res.data);
        setCarId(res.data.carAuctionId);
        setClientId(res.data.clientId);
  
        const defaultInvoiceLines = [
          {
            id: uuid(),
            description: "Car Price",
            priceWithoutVAT: res.data.carPrice,
            vatRate: 0,
            priceWithVAT: parseInt(res.data.carPrice) + 0,
            quantity: 1,
          },
          {
            id: uuid(),
            description: "Transport",
            priceWithoutVAT: res.data.transport,
            vatRate: 0,
            priceWithVAT: parseInt(res.data.transport) + 0,
            quantity: 1,
          },
          {
            id: uuid(),
            description: "Bank Fee",
            priceWithoutVAT: res.data.bankFee,
            vatRate: 0,
            priceWithVAT: parseInt(res.data.bankFee) + 0,
            quantity: 1,
          },
          {
            id: uuid(),
            description: "Auction Service Fee",
            priceWithoutVAT: res.data.auctionServiceFee,
            vatRate: 0,
            priceWithVAT: parseInt(res.data.auctionServiceFee) + 0,
            quantity: 1,
          },
        ];
  
        if (!createNewInvoice) {
          setInvoiceLines(defaultInvoiceLines);
        }
  
        GetActiveInvoiceServices().then((response) => {
          const filteredInvoiceLines = response?.data?.list?.filter(item => 
            invoiceType === 1 ? item.invoiceType === 1 : item.invoiceType !== 1
          ) || [];
          const priceWithoutVat =
            res.data.carPrice < 15000
              ? 250
              : res.data.carPrice >= 15000 && res.data.carPrice < 25000
              ? 450
              : res.data.carPrice >= 25000 && res.data.carPrice < 35000
              ? 650
              : res.data.carPrice >= 35000 && res.data.carPrice < 50000
              ? 850
              : res.data.carPrice >= 50000
              ? 1050
              : 0;
  
          const eur1InvoiceLine = {
            id: uuid(),
            description: `EX-A et EUR1 ${res.data?.registrationNumber}`,
            priceWithoutVAT: priceWithoutVat,
            vatRate: 0,
            priceWithVAT: priceWithoutVat + 0,
            quantity: 1,
          };
  
          setInvoiceLines((prev) => {
            if (createNewInvoice) {
              return [eur1InvoiceLine, ...filteredInvoiceLines];
            }
            return [...defaultInvoiceLines, eur1InvoiceLine, ...filteredInvoiceLines];
          });
        });
  
        setIsReady(true);
      });
    }
  }, [showModal]);
  

  const handleAddInvoiceLine = () => {
    const newInvoiceLine = {
      id: uuid(),
      description: "",
      priceWithoutVAT: 0,
      vatRate: 0,
      priceWithVAT: 0,
      quantity: 1,
    };
    setInvoiceLines((prev) => [...prev, newInvoiceLine]);
  };

  return (
    <Modal
      className="create-invoice-modal"
      title={invoiceType === 1 ? "Create Invoice " : "Create Proforma"}
      centered
      open={showModal}
      onOk={createInvoice}
      onCancel={onCancel}
      okText={"Save"}
    >
      <Preloader isReady={isReady}>
        <div className="client-car-info">
          <div
            className="car-details-info-about-car client-info"
            style={{ width: "50%" }}
          >
            <h1>Client Information</h1>
            <div className="car-details-info-about-car__content">
              <span className="details-title">Name</span>
              <span>{data?.companyName}</span>
            </div>
            <span className="car-details-info-about-car__content-line"></span>
            <div className="car-details-info-about-car__content">
              <span className="details-title">Email</span>
              <span>{data?.clientEmail}</span>
            </div>
            <span className="car-details-info-about-car__content-line"></span>{" "}
            <div className="car-details-info-about-car__content">
              <span className="details-title">City</span>
              <span>{data?.clientCity}</span>
            </div>
            <span className="car-details-info-about-car__content-line"></span>
            <div className="car-details-info-about-car__content">
              <span className="details-title">Address</span>
              <span>{data?.clientAddress}</span>
            </div>
            <span className="car-details-info-about-car__content-line"></span>
            <div className="car-details-info-about-car__content">
              <span className="details-title">Phone Number</span>
              <span>{data?.clientMobileNo}</span>
            </div>
          </div>

          <div
            className="car-details-info-about-car car-info"
            style={{ width: "50%" }}
          >
            <h1>Car Information</h1>
            <div className="car-details-info-about-car__content">
              <span
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "100%",
                }}
              >
                {data?.name}
              </span>
            </div>
            <span className="car-details-info-about-car__content-line"></span>
            <div className="car-details-info-about-car__content">
              <span className="details-title">Brand</span>
              <span>{data?.brand}</span>
            </div>
            <span className="car-details-info-about-car__content-line"></span>{" "}
            <div className="car-details-info-about-car__content">
              <span className="details-title">Registration Number</span>
              <span>{data?.registrationNumber}</span>
            </div>
            <span className="car-details-info-about-car__content-line"></span>
            <div className="car-details-info-about-car__content">
              <span className="details-title">VIN number</span>
              <span>{data?.vinNumber}</span>
            </div>
            <span className="car-details-info-about-car__content-line"></span>
            <div className="car-details-info-about-car__content">
              <span className="details-title"> First registration date</span>
              <span>{data?.firstRegistrationDate}</span>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {" "}
          <div
            style={{ display: "flex", gap: "1rem", margin: "2rem 0 0.5rem 0" }}
          >
            <span
              style={{
                width: "165px",
                fontWeight: "500",
                fontSize: "0.897rem",
                color: "#5e5873",
                fontWeight: "600",
              }}
            >
              Description
            </span>
            <span
              style={{
                width: "165px",
                fontWeight: "500",
                fontSize: "0.897rem",
                color: "#5e5873",
                fontWeight: "600",
              }}
            >
              Price Without VAT
            </span>
            <span
              style={{
                width: "165px",
                fontWeight: "500",
                fontSize: "0.897rem",
                color: "#5e5873",
                fontWeight: "600",
              }}
            >
              VAT Rate
            </span>
            <span
              style={{
                width: "165px",
                fontWeight: "500",
                fontSize: "0.897rem",
                color: "#5e5873",
                fontWeight: "600",
              }}
            >
              Price With VAT
            </span>
          </div>
          <div className="invoiceLines-inputs">
            {invoiceLines?.map((line, index) => {
              return (
                <InvoiceLineInputs
                  invoiceLine={line}
                  invoiceLines={invoiceLines}
                  setInvoiceLines={setInvoiceLines}
                  index={index}
                  descriptionError={descriptionError}
                  showModal={showModal}
                />
              );
            })}
          </div>
          <div className="total-price">
            <Button
              style={{ margin: "1rem 0 0 0", width: "fit-content" }}
              onClick={handleAddInvoiceLine}
              className="action-button edit"
              color="primary"
              outline="true"
            >
              <PlusOutlined
                style={{
                  height: "fit-content",
                  width: "17px",
                  color: "var(--primary-color)",
                }}
              />
              Add new {invoiceType === 1 ? "invoice" : "proforma" } line
            </Button>
            <p>
              Total:{" "}
              <span>
                €
                {invoiceLines?.reduce(
                  (acc, line) => acc + line.priceWithVAT,
                  0
                )}
              </span>
            </p>
          </div>
        </div>
      </Preloader>
    </Modal>
  );
};

export default CreateInvoiceModal;
