import { Modal } from "antd";
import BidTable from "./BidTable";

const BidHistoryModal = ({
  isBidHistoryModalOpen,
  setIsBidHistoryModalOpen,
  bidData,
}) => {
  const handleOk = () => {
    setIsBidHistoryModalOpen(false);
  };
  const handleCancel = () => {
    setIsBidHistoryModalOpen(false);
  };
  return (
    <>
      <Modal
        title="Bid History"
        className="bid-history-modal"
        open={isBidHistoryModalOpen}
        onOk={handleOk}
        centered
        onCancel={handleCancel}
        footer={false}
      >
        <BidTable data={bidData} />
      </Modal>
    </>
  );
};
export default BidHistoryModal;
