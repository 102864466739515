import React, { useEffect, useState } from "react";
import {
  DeleteInvoice,
  DownloadInvoice,
  DownloadInvoice2,
  GetInvoices,
  InvoiceConfirmPaid,
} from "../../endpoint";
import Preloader from "../../utilities/Preloader/Preloader";
import { Button, DatePicker, Pagination, Popconfirm, Tooltip } from "antd";
import { MdOutlineFileDownload } from "react-icons/md";
import InvoiceConfirmationModal from "../Modals/InvoiceConfirmationModal";
import toast from "react-hot-toast";
import { Badge, Table } from "reactstrap";
import Input from "../../utilities/Input/Input";
import CreateNewInvoiceModal from "../Modals/CreateNewInvoiceModal";

const Invoices = () => {
  const [invoices, setInvoices] = useState();
  const [isReady, setIsReady] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedInvoice, setSelectedInvoice] = useState();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [searchValues, setSearchValues] = useState({
    invoiceNo: "",
    companyName: "",
    invoiceDate: "",
    confirmPaidDate: "",
    totalAmount: "",
    isPaid: "",
  });
  const [totalItems, setTotalItems] = useState();
  const [showCreateNewInvoiceModal, setShowCreateNewInvoiceModal] =
    useState(false);
  const [invoiceLines, setInvoiceLines] = useState([]);

  useEffect(() => {
    GetInvoices(currentPage, searchValues, 1, 3).then((res) => {
      setInvoices(res?.data?.invoiceDtoWithLines);
      setIsReady(true);
      setTotalItems(res.data.totalItems);
    });
  }, [currentPage, searchValues]);

  const recallFunc = () => {
    GetInvoices(currentPage, searchValues, 1, 3).then((res) => {
      setInvoices(res?.data?.invoiceDtoWithLines);
      setIsReady(true);
      setTotalItems(res.data.totalItems);
    });
  };

  const onChange = (page) => {
    setCurrentPage(page);
  };

  function getInvoiceStatus(status) {
    if (status) {
      return "Yes";
    }
    if (!status) {
      return "No";
    }
  }

  function getInvoiceClass(status) {
    if (status) {
      return "approved-bg";
    }
    if (!status) {
      return "pending-bg";
    }
  }

  const handleInvoiceConfirmPaid = () => {
    InvoiceConfirmPaid(selectedInvoice, 2).then((res) => {
      if (res.data.success) {
        toast.success("Invoice confirmed successfully!", {
          duration: 2000,
          id: "invoiceConfirmSuccess",
        });
        setInvoices((prevData) =>
          prevData.map((item) =>
            item.id === selectedInvoice
              ? {
                  ...item,
                  isPaid: true,
                  confirmPaidDate: new Date().toISOString(),
                }
              : item
          )
        );
      } else {
        toast.error("Failed to confirm invoice!", {
          duration: 2000,
          id: "invoiceConfirmFailed",
        });
      }
      setShowConfirmationModal(false);
    });
  };

  const handleDeleteInvoice = (id) => {
    DeleteInvoice(id).then((res) => {
      if (res.data.success) {
        toast.success(res.data.message, {
          duration: 2000,
          id: "deleted",
        });
        setInvoices((prev) => {
          return prev.filter((item) => item.id !== id);
        });
      } else {
        toast.error(res.data.message, {
          duration: 2000,
          id: "failedToDelete",
        });
      }
    });
  };

  return (
    <div className="profile-details">
      <div className="profile-details__title">
        <div style={{ display: "flex" }}>
          <h2>Invoices</h2>
          <Button
            className="cars-button create-service-btn"
            onClick={() => {
              setShowCreateNewInvoiceModal(true);
            }}
          >
            Create new invoice
          </Button>
        </div>
        <div className="profile-details__input-content search-content search-content--width">
          <InvoiceConfirmationModal
            showModal={showConfirmationModal}
            confirmInvoice={handleInvoiceConfirmPaid}
            onCancel={() => {
              setShowConfirmationModal(false);
            }}
          />
          {showCreateNewInvoiceModal && (
            <CreateNewInvoiceModal
            recallFunc={recallFunc}
              showModal={showCreateNewInvoiceModal}
              onCancel={() => setShowCreateNewInvoiceModal(false)}
              invoiceLines={invoiceLines}
              setInvoiceLines={setInvoiceLines}
              setInvoices={setInvoices}
            />
          )}
        </div>
        <div className="profile-details__input-content search-content search-content--width cars">
          <Input
            htmlFor={"search"}
            placeholder={""}
            labelText={"Invoice No"}
            type={"text"}
            id="invoiceNo"
            value={searchValues?.invoiceNo}
            onChange={(e) => {
              setSearchValues((prev) => ({
                ...prev,
                invoiceNo: e.target.value,
              }));
            }}
          />
          <Input
            htmlFor={"search"}
            placeholder={""}
            labelText={"Company Name"}
            type={"text"}
            id="companyName"
            value={searchValues?.companyName}
            onChange={(e) => {
              setSearchValues((prev) => ({
                ...prev,
                companyName: e.target.value,
              }));
            }}
          />
          <div className="form-input">
            <label className="input-label">Invoice Date</label>
            <DatePicker
              className="input"
              onChange={(e) => {
                const selectedDate = e?.format("YYYY-MM-DD");
                setSearchValues((prev) => ({
                  ...prev,
                  invoiceDate: selectedDate,
                }));
              }}
              style={{ padding: "9.136px 1rem" }}
            />
          </div>
          <Input
            htmlFor={"search"}
            placeholder={""}
            labelText={"Total Amount"}
            type={"number"}
            id="invoiceNo"
            value={searchValues?.totalAmount}
            onChange={(e) => {
              setSearchValues((prev) => ({
                ...prev,
                totalAmount: e.target.value,
              }));
            }}
          />
          <div className="form-input">
            <label className="input-label">Confirm Paid Date</label>
            <DatePicker
              className="input"
              onChange={(e) => {
                const selectedDate = e?.format("YYYY-MM-DD");
                setSearchValues((prev) => ({
                  ...prev,
                  confirmPaidDate: selectedDate,
                }));
              }}
              style={{ padding: "9.136px 1rem" }}
            />
          </div>
        </div>
        <Preloader isReady={isReady}>
          <Table responsive className="invoices-table invoices">
            <thead>
              <tr className="table-titles">
                <th className="th__width upper-mid-with">Invoice No</th>
                <th className="th__width mid-width">Company Name</th>
                <th className="th__width mid-width">Invoice Date</th>
                <th className="th__width">Total Price</th>
                <th className="th__width">Confirmed Date</th>
                <th className="th__width">Paid</th>
                <th className="th__width"></th>
              </tr>
            </thead>
            <tbody>
              {invoices?.length > 0 &&
                invoices?.map((data, index) => {
                  return (
                    <tr
                      key={data?.id}
                      className={`tr-fontSize ${
                        index % 2 != 0 ? "table-striped" : ""
                      }`}
                    >
                      <td>
                        <span className="align-middle ">{data?.invoiceNo}</span>
                      </td>
                      <td>{data?.companyName}</td>
                      <td>{data?.invoiceDate?.substring(0, 10)}</td>
                      <td>€{data?.totalAmount}</td>
                      <td>{data?.confirmPaidDate?.substring(0, 10)}</td>
                      <td>
                        <Badge
                          pill
                          color="success"
                          className={`me-1 ${getInvoiceClass(data?.isPaid)}`}
                        >
                          {getInvoiceStatus(data?.isPaid)}
                        </Badge>
                      </td>

                      <td>
                        <div
                          className="action-content"
                          style={{ alignItems: "center" }}
                        >
                          <Tooltip title="Download">
                            <MdOutlineFileDownload
                              style={{
                                cursor: "pointer",
                                height: "1.2rem",
                                width: "1.5rem",
                              }}
                              onClick={() => {
                                DownloadInvoice2(data.id, data.invoiceType);
                              }}
                            />
                          </Tooltip>
                          {!data.isPaid && (
                            <Button
                              onClick={() => {
                                setShowConfirmationModal(true);
                                setSelectedInvoice(data?.id);
                              }}
                              className="action-button edit"
                              color="primary"
                              outline="true"
                            >
                              Confirm
                            </Button>
                          )}
                          <Popconfirm
                            title={"Delete Invoice"}
                            description={
                              "Are you sure you want to delete this invoice?"
                            }
                            okText={"Yes"}
                            cancelText={"No"}
                            onConfirm={() => {
                              handleDeleteInvoice(data?.id);
                            }}
                          >
                            <Button type="primary" danger ghost>
                              Delete
                            </Button>
                          </Popconfirm>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              {invoices?.length == 0 && (
                <tr>
                  <td colSpan="11" style={{ textAlign: "center" }}>
                    No results
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <Pagination
            showSizeChanger={false}
            current={currentPage}
            onChange={onChange}
            total={totalItems}
          />
        </Preloader>
      </div>
    </div>
  );
};

export default Invoices;
